<template>
  <div ref="chart-wrapper">
    <vue-c3 ref="line-chart" :handler="handler" class="chart-svg-wrapper"></vue-c3>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import * as d3 from 'd3';
import formatNumber from '~/util/numeral';

const dates1 = [
  'days',
  '11-1-2020',
  '11-2-2020',
  '11-3-2020',
  '11-4-2020',
  '11-5-2020',
  '11-6-2020',
  '11-7-2020',
];

const dates2 = [
  'days1',
  '11-1-2020',
  '11-2-2020',
  '11-3-2020',
  '11-4-2020',
  '11-5-2020',
  '11-6-2020',
  '11-7-2020',
];
const last7 = ['Current', 1, 1, 1, 1, 1, 1, 291297];
const prev7 = ['Previous', 1, 1, 1, 1, 1, 1, 1];

export default {
  name: 'FlipLineChart',
  components: {
    VueC3,
  },
  props: {
    showDiff: {
      type: Boolean,
      required: false,
      default: false,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [dates1, dates2, last7, prev7],
    },
    tooltip: {
      type: Array,
      required: false,
      default: () => [],
    },
    valueType: {
      type: String,
      required: false,
      default: () => '',
    },
    hideLegends: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      handler: new Vue(),
      ro: null,
    };
  },
  computed: {
    options() {
      const vm = this;
      const yAxisValues = [];
      const result = {
        color: {
          pattern: [vm.$flipTheme?.theme?.primaryColor, 'rgba(170,180,200,0.3)'],
        },
        point: {
          r: 3.5,
          focus: {
            expand: {
              r: 4,
            },
          },
        },
        data: {
          xs: {
            Current: 'days',
            Previous: 'days1',
          },
          type: 'spline',
          x: 'days',
          columns: vm.columns,
          xFormat: '%Y-%m-%d',
        },
        transition: {
          duration: 600,
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%m/%d/%Y',
              fit: true,
              rotate: 0,
              count: () => {
                const c = this.columns[0].length - 1;
                if (c >= 14) {
                  return 14;
                }
                return c;
              },
            },
          },
          y: {
            tick: {
              format(d) {
                if (d < 0) {
                  return '';
                }
                yAxisValues.push(d);
                const str = formatNumber(d, vm.valueType, '0');
                return str.replace('.0', '');
              },
            },
          },
        },
        legend: {
          show: !vm.hideLegends,
        },
        tooltip: {
          format: {
            title(d) {
              return `Data ${d}`;
            },
            value(value, ratio, id) {
              const format = id === 'data1' ? d3.format(',') : d3.format('d');
              return format(value);
            },
          },
          contents(d = []) {
            let tooltipBody = '';
            tooltipBody = tooltipBody.concat(`<div class='ctooltip'>`);

            const tooltipRow = vm.tooltip[d[0].index];
            for (let i = 0; i < tooltipRow.length; i++) {
              const { meta } = tooltipRow[i];
              const columns = tooltipRow[i].data;
              tooltipBody = tooltipBody.concat(`<div class='${meta.css}'>`);
              for (let j = 0; j < columns.length; j++) {
                tooltipBody = tooltipBody.concat(`<div>${columns[j]}</div>`);
              }
              tooltipBody = tooltipBody.concat('</div>');
            }
            tooltipBody = tooltipBody.concat('</div>');
            return tooltipBody;
          },
        },
        oninit() {
          d3.select('.c3-line-Last-7-Days')
            .append('linearGradient')
            .attr('id', 'timeframe-gradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', 100)
            .selectAll('stop')
            .data([
              {
                offset: '60%',
                color: '#666',
                opacity: 0,
              },
              {
                offset: '100%',
                color: '#666',
                opacity: 1,
              },
            ])
            .enter()
            .append('stop')
            .attr('offset', function(d) {
              return d.offset;
            })
            .attr('stop-color', function(d) {
              return d.color;
            })
            .attr('stop-opacity', function(d) {
              return d.opacity;
            });
        },
      };
      if (yAxisValues.length < 10) {
        result.axis.y.padding = { bottom: 50 };
      }
      // Collect all data values to be displayed on y-axis
      let allValues = [];
      vm.columns.forEach((entry) => {
        if (entry[0].toUpperCase() !== 'CURRENT' && entry[0].toUpperCase() !== 'PREVIOUS') {
          return;
        }
        allValues = allValues.concat(
          entry
            .map((m) => {
              if (Number.isInteger(m)) {
                return m;
              }
              return Math.ceil(m);
            })
            .filter((f) => Number.isInteger(f))
        );
      });

      // Get max value from all the y-axis values
      const maxValue = Math.max(...allValues);
      // if max value on the y-axis is 10 or less then add equally distributed values to values
      // on y-axis ticks
      if (maxValue <= 10) {
        const tickValuesArray = [];
        for (let i = 0; i <= maxValue; i++) {
          tickValuesArray.push(i);
        }
        result.axis.y.tick.values = tickValuesArray;
      }
      return result;
    },
  },
  watch: {
    columns(n) {
      const vm = this;

      vm.handler.$emit('dispatch', (chart) => {
        chart.load({
          columns: n,
        });
      });
    },
  },
  mounted() {
    const vm = this;
    vm.handler.$emit('init', vm.options);
    this.ro = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        this.handler.$emit('dispatch', (chart) => {
          chart.resize();
        });
      });
    }).observe(this.$refs['chart-wrapper']);
  },
  beforeDestroy() {
    const vm = this;

    vm.handler.$emit('destroy');
    if (this.ro) {
      this.ro.unobserve(this.$refs['chart-wrapper']);
    }
  },
};
</script>

<style lang="scss">
// .sideopen {
//   .chart-svg-wrapper {
//     width: calc(100% - 300px);
//   }
// }
.chart-svg-wrapper {
  width: 100%;
}
.c3 line,
.c3 path {
  fill: transparent;
  stroke: rgba(180, 180, 180, 0.2);
}
.c3 text {
  fill: #7e8890;
}

.c3-line {
  stroke: var(--selfserveprimarycolor) !important;
}

.c3-line {
  stroke-width: 2px;
}
.line-chart {
  width: 100%;
  height: 100%;
}
.ctooltip {
  // width: 180px;
  padding: 10px 15px;
  margin-top: -37px;
  font-size: 12px;
  text-align: left;
  background-color: #1a1b1d;
  border-radius: 4px;
  opacity: 0.96;
}

.lctt-wrap-header {
  margin-bottom: 10px;
  color: #eee;
}
.lctt-wrap-header > div:first-child {
  display: inline-block;
  width: 125px;
  padding-left: 5px;
}
.lctt-wrap-header > div:nth-child(2) {
  display: inline-block;
  width: 110px;
}
.lctt-wrap-header > div:nth-child(3) {
  display: inline-block;
  width: 110px;
}
.lctt-wrap-header > div:last-child {
  display: inline-block;
  float: right;
  width: 40px;
}

.lctt-wrap-type1 {
  color: #006987;
}
.lctt-wrap-type1 > div:first-child {
  display: inline-block;
  width: 100px;
  padding-left: 5px;
}
.lctt-wrap-type1 > div:nth-child(n + 2) {
  display: inline-block;
  width: 100px;
  padding-right: 20px;
  text-align: right;
}
.lctt-wrap-type1 > div:last-child {
  display: inline-block;
  float: right;
  width: 100px;
  padding-right: 10px;
  text-align: right;
}

.lctt-wrap-compare {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #6d707b;
}
.lctt-wrap-compare > div:first-child {
  display: inline-block;
  width: 100px;
  padding-left: 5px;
}
.lctt-wrap-compare > div:nth-child(n + 2) {
  display: inline-block;
  width: 100px;
  padding-right: 20px;
  text-align: right;
}
.lctt-wrap-compare > div:last-child {
  display: inline-block;
  float: right;
  width: 100px;
  padding-right: 10px;
  text-align: right;
}

.lctt-wrap-change {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #eee;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.lctt-wrap-change > div:first-child {
  display: inline-block;
  width: 100px;
  padding-left: 5px;
  text-align: left;
}
.lctt-wrap-change > div:nth-child(n + 2) {
  display: inline-block;
  width: 100px;
  padding-right: 20px;
  text-align: right;
}
.lctt-wrap-change > div:last-child {
  display: inline-block;
  float: right;
  width: 100px;
  padding-right: 10px;
  text-align: right;
}

.lctt-wrap-change > div:not(:first-child) > span.negative-color-caret-down {
  color: #ff3d57;
  &::before {
    padding-right: 0.25rem;
    vertical-align: text-top;
    content: url('data:image/svg+xml; utf8, <svg width="7" height="11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="%23ff3d57" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>');
  }
}

.lctt-wrap-change > div:not(:first-child) > span.negative-color-caret-up {
  color: #ff3d57;
  &::before {
    padding-right: 0.25rem;
    vertical-align: sub;
    content: url('data:image/svg+xml; utf8, <svg width="7" height="11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="%23ff3d57" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>');
  }
}
.lctt-wrap-change > div:not(:first-child) > span.positive-color-caret-up {
  color: #006987;
  &::before {
    padding-right: 0.25rem;
    vertical-align: sub;
    content: url('data:image/svg+xml; utf8, <svg width="7" height="11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="%23006987" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>');
  }
}
.lctt-wrap-change > div:not(:first-child) > span.positive-color-caret-down {
  color: #006987;
  &::before {
    padding-right: 0.25rem;
    vertical-align: text-top;
    content: url('data:image/svg+xml; utf8, <svg width="7" height="11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="%23006987" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>');
  }
}

.lctt-wrap-change > div:not(:first-child) > span.zero {
  color: #cdd9e0;
}

.ctip-perc {
  display: inline-block;
}

.c3-legend {
  margin-top: 20px;
}
.c3-legend-item-tile {
  border-radius: 10px;
}
.c3-shapes circle {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.9);
}

.c3 circle {
  // fill: rgba(170, 180, 200, 0.3);
  fill: none;
  stroke: rgba(222, 222, 222, 0);
  stroke-width: 1px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.c3 .c3-chart-line:first-child circle {
  // fill: rgb(0, 171, 183);
  fill: none;
}

.c3-circle._expanded_ {
  stroke: #ffffff;
  stroke-width: 1px;
  // fill: rgba(222, 222, 222, 0) !important;
}

.redFont {
  color: red !important;
}

.numNeg {
  color: red !important;
}

.numPos {
  color: green !important;
}

.c3 .c3-axis-x .tick:last-of-type text {
  transform: translate(-22px, 0) !important;

  /*font: 13px sans-serif !important;*/
}

.hideChange {
  display: none;
}
</style>
